import React from "react";

export default class SilotypeField extends React.Component {
    constructor(props) {
        super(props);

        this.prefillKey = `${props.name}`;

        this.state = { 
            selectedIndex: this.props.prefillLSManager?.get(this.prefillKey) ?? 0
        };

        this.renderOption = this.renderOption.bind(this);
        this.storePrefill = this.storePrefill.bind(this);
        this.resetForm = this.resetForm.bind(this);

        props.resetObservable?.subscribe(this);
    }

    handleChange(val, index) {
        val = (val || "").trim();
        val = val.length ? val : this.props.removeAction;
        this.props.onFieldUpdated({ value: val, aspect: this.props.name, force: true });
        this.setState({ selectedIndex: index });
        this.storePrefill(index);
    }

    renderOption(optionData, i) {
        var clickValue = this.getClickValue(optionData.displayValue);
        return (
            <span
                className="radio" key={String(optionData.value) + i} data-webm-clickvalue={clickValue}
                onClick={this.handleChange.bind(this, optionData.value, i)}>
                {optionData.displayValue}
            </span>
        );
    }

    getClickValue(name) {
        if (!name) return "";
        switch (name.toLowerCase()) {
            case "all": return "Silo:all";
            case "new": return "Silo:new";
            case "used": return "Silo:used";
            default: "";
        }
    }

    storePrefill(index) {
        index > 0 ? this.props.prefillLSManager?.set(this.prefillKey, index) : this.props.prefillLSManager?.remove(this.prefillKey);
    }

    resetForm() {
        this.props.prefillLSManager?.remove(this.prefillKey);
        this.setState({ selectedIndex: 0 });
    }

    render() {
        var selectionStyles = {
            left: `${this.state.selectedIndex * 33.33}%`
        };

        return (
            <div className="silotype-field" id="search-field-silotype">
                {this.renderOption({ displayValue: "All", value: "" }, 0)}
                {this.props.values.map((o, i) => this.renderOption(o, i + 1))}
                <span className="selected-option bg-primary" style={selectionStyles}></span>
            </div>
        );
    }
}
