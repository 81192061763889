import React from "react";

function DropdownItem(props) {
    var classes = ((props.classes || "") + " dropdown-item").trim();
    if (props.isTop) classes += " top-value";
    if (props.selected) classes += " hover";

    return (
        <div className={classes} onClick={props.onSelected} onMouseEnter={props.onHover} title={props.displayValue}>
            {props.displayValue}
        </div>
    );
}

class DropdownList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectIndex: -1
        };
        var firstItem = {
            type: "value",
            value: "",
            displayValue: this.props.noValueText || this.props.defaultValue
        };
        this.itemsData = [firstItem, ...this.props.items];
        this.keystrokes = [];
        this.lastIndex = -1;
        this.containerRef = React.createRef();

        this.resetKeys = this.resetKeys.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.select = this.select.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }

    componentDidMount() {
        addEventListener("keydown", this.onKeyPress, false);
    }

    componentWillUnmount() {
        removeEventListener("keydown", this.onKeyPress, false);
    }

    resetKeys() {
        if (this.resetKeysTimeout)
            clearTimeout(this.resetKeysTimeout);
        this.resetKeysTimeout = setTimeout(() => {
            this.keystrokes = [];
            this.lastIndex = -1;
        }, 1500);
    }

    onKeyPress(ev) {
        // enables keyboard-selection of items
        if (this.state.selectIndex >= 0 && ev.key.toLowerCase() === "enter") {
            this.props.onItemSelected(this.itemsData[this.state.selectIndex]);
            return;
        }
        // only listen to single-character keys (hack to prevent "shift", "control" etc from being added)
        if (ev.key.length !== 1) return;
        // do not add key if only one character is pressed repeatedly (e.g. press 'c' multiple times to cycle 'c' options)
        if (!(this.keystrokes.length === 1 && this.keystrokes.slice(-1)[0] === ev.key))
            this.keystrokes.push(ev.key.toLowerCase());
        ev.preventDefault();

        var mappedData = this.itemsData.map(item => item.type === "value" ? item.displayValue.toLowerCase() : "");
        var searchStr = this.keystrokes.join("");
        var filter = mappedData.filter(s => s.length && s.startsWith(searchStr));
        this.lastIndex = filter.length ? (this.lastIndex + 1) % filter.length : -1;
        var selection = filter.slice(this.lastIndex, this.lastIndex + 1);
        if (selection.length) {
            var selectedIndex = mappedData.indexOf(selection[0]);
            this.select(selectedIndex);

            // scroll selection into view
            if (this.containerRef.current) {
                var scrollTarget = 10 + 40 * selectedIndex;
                var scrollTop = this.containerRef.current.scrollTop;
                var containerSize = this.containerRef.current.clientHeight;
                if (scrollTop >= 0 && (scrollTop > scrollTarget || scrollTop < scrollTarget - containerSize + 40))
                    this.containerRef.current.scrollTo(0, scrollTarget - containerSize / 2);
            }
        }

        this.resetKeys();
    }

    select(index) {
        this.setState({ selectIndex: index });
    }

    renderItem(item, index, firstBreakIndex) {
        const isSelected = () => index === this.state.selectIndex;
        switch (item.type) {
            case "value":
                return (<DropdownItem
                    key={item.value + index.toString()}
                    onSelected={ev => { ev.stopPropagation(); this.props.onItemSelected(item); }}
                    onHover={() => this.select(index)}
                    selected={isSelected()}
                    isTop={index < firstBreakIndex}
                    {...item}>
                </DropdownItem>);
            case "label":
                if (item.displayValue)
                    return (<DropdownItem
                        key={item.value + index.toString()}
                        onSelected={ev => { ev.stopPropagation(); this.props.onItemSelected(item); }}
                        onHover={() => this.select(index)}
                        selected={isSelected()}
                        classes="dropdown-label"
                        {...item}>
                    </DropdownItem>);
                else
                    return (<div
                        key={item.value + index.toString()}
                        className="dropdown-label"
                        title={item.displayValue}>
                        {item.displayValue}
                    </div>);
            case "break":
                return (<hr key={`break-${index}`}></hr>);
            default:
                return null;
        }
    }

    render() {
        var firstBreakIndex = this.itemsData.findIndex(item => item.type === "break");
        const listItems = this.itemsData.map((item, index) => this.renderItem(item, index, firstBreakIndex));
        return (<div className="dropdown-box" ref={this.containerRef}>{listItems}</div>);
    }
}

export default class DropdownField extends React.Component {
    constructor(props) {
        super(props);
        this.getDisplayValue = this.getDisplayValue.bind(this);

        this.prefillKey = props.prefillKey ?? `${props.name}`; // handling of "range" type

        this.state = {
            open: false,
            displayValue: props.prefillLSManager?.get(this.prefillKey) ?? this.getDisplayValue()
        };

        this.isDisabled = this.isDisabled.bind(this);
        this.toggleOpen = this.toggleOpen.bind(this);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
        this.checkClose = this.checkClose.bind(this);
        this.itemSelected = this.itemSelected.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.storePrefill = this.storePrefill.bind(this);

        props.resetObservable?.subscribe(this);
    }

    getDisplayValue() { return this.props.value || ""; }

    isDisabled() { return this.props.disable || !this.props.values || this.props.values.length < 1; }

    componentDidMount() {
        window.addEventListener("mousedown", this.checkClose, false);
        window.addEventListener("blur", this.checkClose, false);
    }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.checkClose, false);
        window.removeEventListener("blur", this.checkClose, false);
    }

    toggleOpen() {
        if (this.isDisabled()) return;
        this.setState(oldState => ({ open: !oldState.open }));
    }

    mouseEnter() { this.mouseIn = true; }
    mouseLeave() { this.mouseIn = false; }

    checkClose() { if (!this.mouseIn) this.setState({ open: false }); }

    itemSelected(item) {
        var value = item.value || this.props.defaultValue;
        this.setState({ displayValue: item.displayValue });
        this.toggleOpen();
        if (this.props.onItemSelected)
            this.props.onItemSelected({ value: value, displayValue: item.displayValue });
        if (this.props.onFieldUpdated)
            this.props.onFieldUpdated({ value: value, aspect: this.props.name });
        this.storePrefill(item.displayValue);
    }

    componentDidUpdate(prevProps) {
        var validDefaults = [this.props.noValueText, this.props.title];
        var isValidDefault = validDefaults.some(v => v === this.state.displayValue);

        if (prevProps.value && !this.props.value) {
            // no need to update display when clearing value
            // without this, the 'empty' value will be cleared from state.displayValue
            // whitelisted displays include the 'empty' text and the field's name
            if (isValidDefault) return;
        }

        if (prevProps.value !== this.props.value) {
            // props have updated the value (from ajax)
            // need to update the display to reflect the new value
            if (this.state.displayValue !== this.getDisplayValue()) {
                this.props.prefillLSManager?.remove(this.prefillKey);
                return this.setState({ displayValue: this.getDisplayValue() });
            }
        }
    }

    storePrefill(displayValue) {
        displayValue ? this.props.prefillLSManager?.set(this.prefillKey, displayValue) : this.props.prefillLSManager?.remove(this.prefillKey);
    }

    resetForm() {
        this.setState({ displayValue: "" });
        this.props.prefillLSManager?.remove(this.prefillKey);
    }

    render() {
        var classes = `${(this.props.classes || "search-field dropdown-search")} dropdown-main arrow-down`;
        if (this.props.emphasis) classes += " search-field-emphasis";
        if (this.state.open) classes += " dropdown-open";
        var displayValue = `${this.props.prefix || ""} ${this.props.prefillLSManager?.get(this.prefillKey) ?? this.state.displayValue}`.trim();
        var id = `search-field-${this.props.title.toLowerCase().split(" ").join("-")}`;

        return (
            <div className={classes} id={id} onClick={this.toggleOpen} onMouseDown={this.mouseEnter} onMouseUp={this.mouseLeave} disabled={this.isDisabled()} >
                <div className="dropdown-value" data-display-value={displayValue}>
                    <span>{displayValue}</span>
                    <label>{this.props.title}</label>
                </div>
                {this.state.open && <DropdownList
                    items={this.props.values}
                    defaultValue={this.props.title}
                    noValueText={this.props.noValueText}
                    onItemSelected={this.itemSelected} />}
            </div>
        );
    }
};
