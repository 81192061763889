import React from "react";
import TextField from "../Fields/text-field";
import DropdownField from "../Fields/dropdown-field";
import RangeField from "../Fields/range-field";
import NLSField from "../Fields/nls-field";

export default class SearchForm extends React.Component {
    constructor(props) {
        super(props);

        const mapFieldToAspect = (field) => field.name;
        this.primaryFieldAspects = props.primaryFields.map(mapFieldToAspect);
        this.secondaryFieldAspects = props.secondaryFields.map(mapFieldToAspect);

        this.buildState = this.buildState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.handleFieldUpdated = this.handleFieldUpdated.bind(this);
        this.onExpressionUpdate = this.onExpressionUpdate.bind(this);
        this.renderField = this.renderField.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);

        this.state = this.buildState(props);

        // custom event tracking
        this.isNls = !!this.props.isNls;
        this.interactions = new Set();
        this.nlsRef = React.createRef();
    }

    buildState(source) {
        return ({
            heading: source.heading,
            subHeading: source.subHeading,
            primaryFields: source.primaryFields,
            secondaryFields: source.secondaryFields,
            searchButtonUrl: source.searchButtonUrl,
            searchButtonText: source.searchButtonText,
            expression: source.expression,
            disableSearch: source.disableSearch,
            forceDisable: false
        });
    }

    onServerResponse(xhr) {
        if (!xhr.responseText || xhr.status !== 200) return;

        var serverResponse = JSON.parse(xhr.responseText);
        this.setState(this.buildState(serverResponse));

        // custom event tracking
        if (this.isNls && serverResponse.disableSearch) {
            CsnInsights.metaData.searchfacetorder = [...this.interactions].join();
            CsnInsights.metaData.searchkeyword = this.nlsRef.current.state.displayValue;
            CsnInsights.metaData.searchexpression = serverResponse.expression;
            CsnInsightsEventTracker.sendEvent(CsnInsights.metaData, "nls-search", "zero-result", "click");
        }
    }

    handleFieldUpdated(fieldData) {
        if (this.state.expression === fieldData.value && !fieldData.force) return;

        this.setState({ forceDisable: true, expression: fieldData.value });
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("load", () => this.onServerResponse(xhr));
        xhr.open("POST", `/_homepage/v1/search/?tenantName=${this.props.tenant || ""}`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({ expression: fieldData.value, expressionArgs: fieldData.args }));

        // custom event tracking
        this.interactions.add(fieldData.aspect);
    }

    onExpressionUpdate(newExpression) {
        // update button url with best guess for non-seo listings page
        // api will return correct url, this is to patch the delay
        var btnUrl = this.props.searchUrl;
        if (!btnUrl) return;
        btnUrl = btnUrl.trim();
        btnUrl = btnUrl.startsWith("/") ? btnUrl : "/" + btnUrl;
        btnUrl = btnUrl.endsWith("/") ? btnUrl : btnUrl + "/";
        this.setState({ expression: newExpression, searchButtonUrl: `${btnUrl}?q=${newExpression}` });
    }

    renderField(fieldData) {
        const props = { key: fieldData.name, disable: this.state.forceDisable, onFieldUpdated: this.handleFieldUpdated };
        switch (fieldData.type) {
            case "text":
                return (<TextField {...props} {...fieldData} onExpressionUpdate={this.onExpressionUpdate} />);
            case "dropdown":
                return (<DropdownField {...props} {...fieldData} />);
            case "range":
                return (<RangeField {...props} {...fieldData} />);
            case "NLS":
                return (<NLSField ref={this.nlsRef} {...props} {...fieldData} tenant={this.props.tenant} />);
            default:
                return null;
        }
    }

    onClickSearch(ev) {
        if (this.state.disableSearch)
            return ev.preventDefault();

        if (!this.isNls || !this.nlsRef.current)
            return; // below logic is for NLS only

        // localStorage key for qualtrics survey
        if (localStorage)
            localStorage.setItem("nls-survey", true);

        // logic to default NLS text to keyword expression
        var nlsComponent = this.nlsRef.current;
        if (nlsComponent.props.removeAction !== nlsComponent.props.expression) {
            if (nlsComponent.state.keyword)
                nlsComponent.feedback(); // feedback for NLS AI when bypassing Xavier with keyword
            return; // ignore if nls field is in 'selected' state (faceted)
        }
        if (!nlsComponent.state.displayValue || !nlsComponent.state.displayValue.trim())
            return; // ignore if nls field is empty

        // feedback for NLS AI when defaulting to keyword
        nlsComponent.feedback();

        // default to keyword
        ev.preventDefault();
        var newQuery = nlsComponent.props.keywordExpression.replace("{0}", nlsComponent.state.displayValue);
        var el = document.createElement("a");
        el.setAttribute("href", `${this.props.searchUrl}?q=${encodeURIComponent(newQuery)}`);
        el.dispatchEvent(new MouseEvent("click", ev));
    }

    render() {
        var showSubHeading = this.state.subHeading && this.state.subHeading.length;
        var searchBtnClasses = "search-form-submit text-white";
        if (!this.state.disableSearch) searchBtnClasses += " bg-primary";
        return (
            <div className="fadein">
                <div className="fields-wrapper fields-wrapper-primary">
                    {this.state.primaryFields.map(this.renderField)}
                    <a className={searchBtnClasses} href={this.state.searchButtonUrl}
                        disabled={this.state.disableSearch} onClick={this.onClickSearch} data-webm-clickvalue="search"
                        data-opm-trackon="click" data-opm-event="click-search-button-homepage">
                        <span>{this.state.searchButtonText}</span>
                    </a>
                </div>
                <div className="search-form-bottom">
                    <div className="fields-wrapper fields-wrapper-secondary">
                        {this.state.secondaryFields.map(this.renderField)}
                    </div>
                    {showSubHeading && <h6 className="sub-heading">{this.state.subHeading}</h6>}
                    {this.props.otherLinkUrl && this.props.otherLinkText &&
                        <a className="other-link" href={this.props.otherLinkUrl}>{this.props.otherLinkText}</a>}
                </div>
            </div>
        );
    }
};
