import React from "react";
import TextField from "../Fields/text-field";
import DropdownField from "../Fields/dropdown-field";
import RangeField from "../Fields/range-field";
import SilotypeField from "../Fields/legacy-silotype-field";
import { PrefillLocalStorageManager } from "../Fields/local-storage-manager";

class ResetObservable {
    constructor() {
        this.subscribers = [];
    }

    subscribe(component) {
        this.subscribers.push(component);
    }

    resetForm() {
        for (const component of this.subscribers) {
            if (component.resetForm) {
                component.resetForm();
            }
        }
    }
}

export default class LegacyForm extends React.Component {
    constructor(props) {
        super(props);

        this.defaultExpression = props.expression;
        this.resetObservable = new ResetObservable();
        this.PREFILL_EXPRESSION_KEY = "__prefill_expression__";
        this.prefillLSManager = new PrefillLocalStorageManager();

        this.buildState = this.buildState.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.handleFieldUpdated = this.handleFieldUpdated.bind(this);
        this.onExpressionUpdate = this.onExpressionUpdate.bind(this);
        this.renderField = this.renderField.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.storePrefillExpression = this.storePrefillExpression.bind(this);

        this.state = this.buildState(this.props);
        if (this.props.formReset) this.resetForm();
        this.loadPrefill();
    }

    loadPrefill() {
        const prefillExpression = this.prefillLSManager.get(this.PREFILL_EXPRESSION_KEY);
        if (!prefillExpression) return;
        this.getSearchFormConfig(prefillExpression);
    }

    buildState(source) {
        var fields = source.primaryFields.concat(source.secondaryFields);
        return ({
            heading: source.heading,
            subHeading: source.subHeading,
            fields: fields.filter(f => f.name !== "Condition"),
            silotypeData: fields.find(f => f.name === "Condition"),
            searchButtonUrl: source.searchButtonUrl,
            searchButtonText: source.searchButtonText,
            expression: source.expression,
            disableSearch: source.disableSearch,
            tenant: source.tenant,
            forceDisable: false
        });
    }

    onServerResponse(xhr) {
        if (!xhr.responseText || xhr.status !== 200) return;

        var serverResponse = JSON.parse(xhr.responseText);
        this.setState(this.buildState(serverResponse));
    }

    handleFieldUpdated(fieldData) {
        if (this.state.expression === fieldData.value && !fieldData.force) return;

        this.setState({ forceDisable: true, expression: fieldData.value });

        this.getSearchFormConfig(fieldData.value);
        this.storePrefillExpression(fieldData.value);
    }

    getSearchFormConfig(expression) {
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("load", () => this.onServerResponse(xhr));
        xhr.open("POST", `/_homepage/v1/search/?tenantName=${this.props.tenant}`);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({ expression }));
    }

    onExpressionUpdate(newExpression) {
        // update button url with best guess for non-seo listings page. api will return correct url
        // this is to patch the delay from user's keystroke to api response
        // there are two delays - hardcoded after the user types, then the api call delay
        var btnUrl = this.props.searchUrl;
        if (!btnUrl) return;
        btnUrl = btnUrl.trim();
        btnUrl = btnUrl.startsWith("/") ? btnUrl : "/" + btnUrl;
        btnUrl = btnUrl.endsWith("/") ? btnUrl : btnUrl + "/";
        this.setState({ expression: newExpression, searchButtonUrl: `${btnUrl}?q=${newExpression}` });
    }

    renderField(fieldData) {
        const props = { key: fieldData.name, disable: this.state.forceDisable, onFieldUpdated: this.handleFieldUpdated };

        switch (fieldData.type) {
            case "text":
                return (<TextField {...props} {...fieldData} onExpressionUpdate={this.onExpressionUpdate.bind(this)} resetObservable={this.resetObservable} prefillLSManager={this.prefillLSManager} />);
            case "dropdown":
                return (<DropdownField {...props} {...fieldData} resetObservable={this.resetObservable} prefillLSManager={this.prefillLSManager} />);
            case "range":
                return (<RangeField {...props} {...fieldData} resetObservable={this.resetObservable} prefillLSManager={this.prefillLSManager} />);
            default:
                return null;
        }
    }

    storePrefillExpression(value) {
        value ? this.prefillLSManager.set(this.PREFILL_EXPRESSION_KEY, value) : this.prefillLSManager.remove(this.PREFILL_EXPRESSION_KEY);
    }

    resetForm() {
        this.resetObservable.resetForm();
        this.prefillLSManager.clear();
        this.getSearchFormConfig(this.defaultExpression);
    }

    render() {
        var onclick = this.state.disableSearch ? e => e.preventDefault() : null;
        var searchBtnClasses = "search-form-submit text-white";
        if (!this.state.disableSearch) searchBtnClasses += " bg-primary";
        var searchText = this.state.searchButtonText;
        var subHeading = this.state.subHeading;
        if (searchText === "Show me 0 cars") {
            searchText = "No cars available";
            subHeading = "Your search returned zero cars. Try adjusting your criteria."
        }
        var showHeading = this.state.tenant.includes("redbook") ? true : false;

        return (
            <>
                <div className="form-top">
                    
                    {!showHeading && <h1 className="heading display-4">{this.state.heading}</h1>}
                    {this.state.silotypeData && <SilotypeField {...this.state.silotypeData} disable={this.state.forceDisable} onFieldUpdated={this.handleFieldUpdated} resetObservable={this.resetObservable} prefillLSManager={this.prefillLSManager} />}
                </div>

                <div className="search-form">
                    <div className="form-top">
                        {showHeading && <h1 className="heading-inside display-4">{this.state.heading}</h1>}
                    </div>
                    <div className="fields-wrapper fields-wrapper-primary fadein">
                        {this.state.fields.map(this.renderField)}
                    </div>
                    {<a className="reset-search-label" href="#" onClick={this.resetForm} data-opm-trackon="click" data-opm-event="previous-search-reset">Clear All</a>}
                </div>

                <a className={searchBtnClasses} href={this.state.searchButtonUrl}
                    disabled={this.state.disableSearch} onClick={onclick} data-webm-clickvalue="search"
                    data-opm-trackon="click" data-opm-event="click-search-button-homepage">
                    <span>{searchText}</span>
                </a>
                <div className="sub-heading">{subHeading}</div>

            </>
        );
    }
}