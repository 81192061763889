import React from "react";

export default class TextField extends React.Component {
    constructor(props) {
        super(props);

        this.onChangeTimeout = null;
        this.prefillKey = `${props.name}`;

        this.state = {
            value: this.props.prefillLSManager?.get(this.prefillKey) ?? ""
        }
        
        this.getExpression = this.getExpression.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.resetForm = this.resetForm.bind(this);

        props.resetObservable?.subscribe(this);
    }

    getExpression(val) {
        return val.length
            ? (this.props.expression || "{0}").replace("{0}", val)
            : this.props.removeAction;
    }

    handleChange({ target: { value }}) {
        this.setState({ value });
        var val = (value && value.trim()) || "";
        this.storePrefill(val);

        this.props.onExpressionUpdate(this.getExpression(val));

        clearTimeout(this.onChangeTimeout);
        this.onChangeTimeout = setTimeout((() => {
            this.onChangeTimeout = null;
            this.props.onFieldUpdated({ value: this.getExpression(val), aspect: this.props.name, force: true });
        }).bind(this), 1000);
    }

    storePrefill(value) {
        value ? this.props.prefillLSManager?.set(this.prefillKey, value) : this.props.prefillLSManager?.remove(this.prefillKey);
    }

    resetForm() {
        this.props.prefillLSManager?.remove(this.prefillKey);
        this.setState({ value: "" });
    }

    render() {
        var classes = "search-field text-search";
        if (this.props.emphasis) classes += " search-field-emphasis";
        var id = `search-field-${this.props.title.toLowerCase().split(" ").join("-")}`;

        return (
            <div className={classes} id={id} disabled={this.props.disable}>
                <input type="text" placeholder=" " disabled={this.props.disabled} onChange={this.handleChange} value={this.state.value}/>
                <label>{this.props.title}</label>
            </div>
        );
    }
};
