export class PrefillLocalStorageManager {
    constructor() {
        this.KEY = "__homepage_search_prefill__";
        this.obj = JSON.parse(localStorage.getItem(this.KEY)) ?? {};
    }

    get(key) {
        return this.obj[key];
    }

    set(key, value) {
        this.obj[key] = value;
        localStorage.setItem(this.KEY, JSON.stringify(this.obj));
        return this;
    }

    remove(key) {
        delete this.obj[key];
        localStorage.setItem(this.KEY, JSON.stringify(this.obj));
        return this;
    }

    clear() {
        localStorage.removeItem(this.KEY);
        this.obj = {};
        return this;
    }
}
