import SearchFormRenderer from "./Components/search-form-renderer";
require("./search-form.scss");
require("./legacy-form.scss");
require("./nls-form.scss");

(function (formData) {
    if (!formData) return;
    const container = document.getElementById("search-form-container");
    const searchFormRenderer = new SearchFormRenderer(container, formData);
    searchFormRenderer.render();
})(searchFormData);
