import React from "react";
import ReactDom from "react-dom";
import SearchForm from "./Forms/default-form";
import LegacyForm from "./Forms/legacy-form";

function hjTag(tag) {
    if (hj)
        hj("tagRecording", [tag]);
    else
        w.document.addEventListener("DOMContentLoaded", () => hj && hj("tagRecording", [ tag ]), { capture: true });
}

function activateNLS() {
    var v = window.optimizelyUtil.activate("bikes-desktop-nls-home");
    if (v) {
        if (localStorage) localStorage.setItem("nls-variant", v);
        hjTag(`bikes-nls-${v}`);
    }
}

export default class SearchFormRenderer {
    constructor(container, data) {
        this._container = container;
        this._formData = data;
        this._version = data.version;
    }

    render() {
        ReactDom.render(this._getForm(), this._container);

        if (this._version === 2) {
            if (window.optimizelyUtil)
                activateNLS();
            else
                document.addEventListener("optimizely-init", activateNLS, { capture: true });
        }

        if (window.optimizelyUtil) {
            window.optimizelyUtil.activate("cars-desktop-prefill-home");
        } else {
            document.addEventListener("optimizely-init", () => window.optimizelyUtil.activate("cars-desktop-prefill-home"), { capture: true });
        }
    }

    destroy() {
        ReactDom.unmountComponentAtNode(this._container);
    }

    _getForm() {
        switch(this._version) {
            case 0: return (<LegacyForm {...this._formData} />);
            case 2:
                return (<SearchForm {...this._formData} isNls={true} />);
            case 1:
            default: return (<SearchForm {...this._formData} />);
        }
    }
};
