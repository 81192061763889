import React from "react";

// upper bound for showing keyword option
const KEYWORD_LIMIT = 10;

class Control {
    constructor(key) {
        if (this.constructor.name === "Control")
            throw new Error("cannot instansiate `Control` objects");
        this.key = key;
    }
    handle() { throw new Error("handle() must be overwritten in child"); }
}

class UpControl extends Control {
    constructor() { super("ArrowUp"); }
    handle(component) {
        if (!component.state.open) return;
        component.setState(prevState => {
            var index = Math.max(prevState.active - 1, 0);
            if (prevState.active < 0) index = prevState.options.length;
            return { open: true, active: index };
        });
    }
}

class DownControl extends Control {
    constructor() { super("ArrowDown"); }
    handle(component) {
        if (!component.state.options || !component.state.options.length)
            return;
        component.setState(prevState => {
            var index = Math.min(prevState.active + 1, prevState.options.length);
            return { open: true, active: index };
        });
    }
}

class EnterControl extends Control {
    constructor() { super("Enter"); }
    handle(component) {
        // do keyword if no 'active' option
        if (!component.state.keyword && component.state.active < 0)
            return component.onUseKeyword();
        // do nothing else if closed
        if (!component.state.open) return;
        // select keyword if last option is active (assumes keyword is always present)
        if (component.state.options && component.state.active === component.state.options.length)
            return component.onUseKeyword();
        // select active option
        var opt = component.state.options[component.state.active];
        if (opt)
            return component.onSelectOption(opt);
    }
}

class EscControl extends Control {
    constructor() { super("Escape"); }
    handle(component) {
        if (!component.state.open) return;
        component.setState({ open: false, active: -1 });
        component.cancelRequest(true);
    }
}

const KeyboardControls = [ new UpControl(), new DownControl(), new EnterControl(), new EscControl() ];

function post(path, body, onLoad = null) {
    var req = new XMLHttpRequest();
    if (onLoad) req.addEventListener("load", () => onLoad(req));
    req.open("POST", path);
    req.setRequestHeader("Content-Type", "application/json");
    req.send(JSON.stringify(body));
    return req;
}

function KeywordPrompt({ search }) {
    return (<>
        <span className="text-primary">Keyword search</span>
        {search.length > 0 && ` ${search}`}
    </>);
}

function NoResults({ search, onKeyword, active }) {
    var keywordWrapClasses = "keyword-prompt";
    if (active) keywordWrapClasses += " active";

    return (
        <div className="no-results">
            <p>No matches for <b>{search}</b></p>
            <p>Suggestions:</p>
            <ul>
                <li>Try typing a make or model.</li>
                <li>Make sure that words are spelled correctly.</li>
                <li><p className={keywordWrapClasses} onClick={onKeyword}>
                    <KeywordPrompt search={search}/>
                </p></li>
            </ul>
        </div>
    );
}

function getOptionDisplay(option) {
    var type = option.aspectContext["Type"];
    if (!option.displayText)
        return highlightText(type, option.highlight);
    var highlighted = highlightText(option.displayText, option.highlight);
    if (!type)
        return highlighted;
    return (<>{highlighted} - <span className="text-primary">in {type}</span></>);
}

function highlightText(str, highlight) {
    highlight = (highlight || "").toLowerCase().trim();
    var start = str.toLowerCase().indexOf(highlight);
    var firstBit = str.slice(start, start + highlight.length);
    if (!firstBit) return str;
    return (<>{str.slice(0, start)}<b>{firstBit}</b>{str.slice(start + highlight.length)}</>);
}

function NLSOption(props) {
    var display = getOptionDisplay(props);
    var clickAction = () => props.onSelect(props, { id: props.id, value: props.action, text: props.displayText });
    var className = "opt";
    if (props.active) className += " active";
    return (<div className={className} onClick={clickAction} onMouseEnter={props.onHover}>{display}</div>);
}

export default class NLSField extends React.Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.fieldRef = React.createRef();
        this.typeTimeout = null;
        this.lastVal = "";
        this.feedbackLog = null;
        this.pending = null;
        this.cancelled = false;

        this.onType = this.onType.bind(this);
        this.onKeyboardNavigation = this.onKeyboardNavigation.bind(this);
        this.onClearClick = this.onClearClick.bind(this);
        this.onDomClick = this.onDomClick.bind(this);
        this.onUseKeyword = this.onUseKeyword.bind(this);
        this.onOptionHover = this.onOptionHover.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.cancelRequest = this.cancelRequest.bind(this);
        this.onServerResponse = this.onServerResponse.bind(this);
        this.onSelectOption = this.onSelectOption.bind(this);
        this.feedback = this.feedback.bind(this);
        this.update = this.update.bind(this);

        this.state = {
            options: [],
            displayValue: "",
            open: false,
            loading: false,
            keyword: false,
            active: -1
        };
    }

    componentDidMount() { document.addEventListener("click", this.onDomClick, { capture: true }); }
    componentWillUnmount() { document.removeEventListener("click", this.onDomClick); }

    onKeyboardNavigation(ev) {
        var control = KeyboardControls.find(c => c.key === ev.key);
        if (!control) return;

        ev.preventDefault();
        control.handle(this);
    }

    onType(ev) {
        var currentVal = ev.target.value;
        if (this.lastVal === currentVal) return;
        this.lastVal = currentVal;

        var isEmpty = currentVal === "";
        this.setState((prevState) => {
            var options = isEmpty ? [] : prevState.options;
            return {
                options,
                displayValue: currentVal,
                open: !isEmpty && options.length > 0,
                loading: true,
                keyword: isEmpty ? false : prevState.keyword
            };
        });

        if (this.props.expression !== this.props.removeAction) { // if faceted
            this.update(this.props.removeAction); // remove selection on type
            this.setState({ keyword: false });
        }
        if (!isEmpty) {
            clearTimeout(this.typeTimeout);
            this.typeTimeout = setTimeout(() => this.getOptions(this.props.expression, currentVal), 250);
        }
    }

    onClearClick(ev) {
        ev.preventDefault();
        ev.stopPropagation(); // avoid calling onDomClick
        this.cancelRequest(true);
        this.setState({ displayValue: "", options: [], open: false, loading: false, keyword: false, active: -1 });
        this.update(this.props.removeAction);
        this.lastVal = "";
        this.fieldRef.current.focus();
    }

    onDomClick(ev) {
        function isChild(el, parent) {
            while (el && el.tagName.toLowerCase() !== "body") {
                el = el.parentNode;
                if (el === parent) return true;
            }
            return false;
        }

        // external click
        if (!isChild(ev.target, this.wrapperRef.current))
            this.setState({ open: false });
        // internal click
        else if (!this.state.open && this.state.options.length)
            this.setState({ open: true });
    }

    onUseKeyword() {
        this.setState({ options: [], open: false, keyword: true, active: -1 });
        this.update(this.props.keywordExpression, { 0: this.state.displayValue.trim() });
    }

    onOptionHover(i) { this.setState({ active: i }); }

    getOptions(expression, search, noOpen = false) {
        this.cancelRequest();

        this.pending = post(
            `/_homepage/v1/nls/?tenantName=${this.props.tenant || ""}`,
            { expression, search },
            req => this.onServerResponse(req, noOpen));
    }

    cancelRequest(force = false) {
        if (this.pending && this.pending.status === 0)
            this.pending.abort();

        clearTimeout(this.typeTimeout);
        this.cancelled = force;
    }

    onServerResponse(req, noOpen) {
        if (this.cancelled || !req.responseText || req.status !== 200) {
            this.setState({ open: false, loading: false, active: -1 });
            this.cancelled = false;
            return;
        }

        var { options, feedbackLog } = JSON.parse(req.responseText);
        this.feedbackLog = feedbackLog;
        this.setState((prevState) => {
            var open = options.length || !!prevState.displayValue;
            if (noOpen) open = prevState.open;
            return { options, open, loading: false, active: -1 };
        });
    }

    onSelectOption(option) {
        this.setState({ options: [], displayValue: option.displayText, open: false, keyword: false, active: -1 });
        this.update(option.action);
        this.feedback(option.id);
    }

    feedback(optionId = null) {
        post(`/_homepage/v1/nls/feedback/?tenantName=${this.props.tenant || ""}`,
            { feedbackLog: this.feedbackLog, optionId, query: this.state.displayValue });
    }

    update(value, args = {}) {
        if (this.props.onFieldUpdated)
            this.props.onFieldUpdated({ value, args, aspect: "NLS" });
    }

    componentDidUpdate(prevProps) {
        if (this.props.expression === prevProps.expression) return;
        if (!this.state.displayValue) return;
        this.getOptions(this.props.expression, this.state.displayValue, true);
    }

    render() {
        const focusInput = ev => { ev.preventDefault(); this.fieldRef.current.focus(); };
        var optionList = this.state.options.map((o, i) =>
            (<NLSOption {...o} key={o.id} highlight={this.state.displayValue} active={this.state.active === i} onSelect={this.onSelectOption} onHover={() => this.onOptionHover(i)}/>));
        var nOptions = optionList.length;
        var fieldClass = "search-field text-search nls-search";
        if (this.state.keyword) fieldClass += " keyword-mode";
        var clearClass = "icon icon-close";
        if (this.state.displayValue) clearClass += " visible";
        var keywordWrapClasses = "opt";
        if (this.state.active === nOptions) keywordWrapClasses += " active";

        return (
            <div className={fieldClass} ref={this.wrapperRef} onClick={focusInput}>
                <span className="icon icon-search"></span>
                <input type="text" placeholder={this.props.title} value={this.state.displayValue} ref={this.fieldRef}
                    onChange={this.onType} onKeyDown={this.onKeyboardNavigation} onBlur={this.onBlur}/>
                <span className={clearClass} onClick={this.onClearClick}></span>
                <label>Keyword</label>
                {this.state.open && <div className="nls-options" onMouseLeave={() => this.setState({ active: -1 })}>
                    {nOptions > 0 && 
                        <>
                            <div className="option-title">Choose a suggestion:</div>
                            {optionList}
                        </>}
                    {nOptions > 0 && nOptions <= KEYWORD_LIMIT &&
                        <>
                            <hr/>
                            <div className={keywordWrapClasses} onClick={this.onUseKeyword} onMouseEnter={() => this.onOptionHover(this.state.options.length)}>
                                <KeywordPrompt search={this.state.displayValue}/>
                            </div>
                        </>}
                    {nOptions === 0 && !this.state.loading &&
                        <NoResults search={this.state.displayValue} active={this.state.active === nOptions} onKeyword={this.onUseKeyword}/>}
                </div>}
            </div>
        );
    }
}
