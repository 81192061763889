import React from "react";
import DropdownField from "./dropdown-field";

export default class RangeField extends React.Component {
    constructor(props) {
        super(props);

        this.leftValue = "";
        this.rightValue = "";

        this.buildValue = this.buildValue.bind(this);
    }

    deconstructValue(value) {
        var split = value.split(",");
        return split.length === 2 ? split : [ "", "" ];
    }

    buildValue() {
        if (!this.leftValue && !this.rightValue)
            return this.props.defaultValue;
        var expression = this.props.expression || "{0}";
        var left = this.leftValue;
        var right = this.rightValue;

        if (!isNaN(left) && !isNaN(right) && parseInt(left) > parseInt(right)) {
            var originalLeftValue = left;
            left = right;
            right = originalLeftValue;
        }

        return expression.replace("{0}", `${left}..${right}`);
    }

    itemSelected(isLeft, item) {
        if (isLeft) this.leftValue = item.value || "";
        else this.rightValue = item.value || "";
        this.props.onFieldUpdated({ aspect: this.props.name, value: this.buildValue() });
    }

    render() {
        var classes = "range range-min";
        var disabled = this.props.disable || !this.props.values || this.props.values.length < 1;

        return (
            <div className="search-field range-search" disabled={disabled}>
                <DropdownField values={this.props.values} title={`${this.props.title} ${this.props.fromTitle}`} onItemSelected={this.itemSelected.bind(this, true)} classes={classes} prefillKey={`${this.props.name}_${this.props.fromTitle}`} resetObservable={this.props.resetObservable} prefillLSManager={this.props.prefillLSManager}/>
                <DropdownField values={this.props.values} title={`${this.props.title} ${this.props.toTitle}`} onItemSelected={this.itemSelected.bind(this, false)} classes={classes} prefillKey={`${this.props.name}_${this.props.toTitle}`} resetObservable={this.props.resetObservable} prefillLSManager={this.props.prefillLSManager}/>
            </div>
        );
    }
};
